import React from "react"
import { graphql, StaticQuery } from "gatsby"

import { Container } from "@material-ui/core"

import Slider from "react-slick"
import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"

class TestimonialSlider extends React.Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }

  render() {
    const settings = {
      infinite: true,
      dots: true,
      fade: true,
      autoplay: true,
      autoplaySpeed: 9000,
      speed: 300,
      slidesToShow: 1,
      slide: "div",
      slidesToScroll: 1,
    }

    return (
      <div className="Grey">
        <Container>
          <div className="testimonial-container py-24">
            <h3 className="orange">
              At Beachcomber Barrie, your satisfaction is our passion!
            </h3>
            <Slider
              ref={Testimonials => (this.slider = Testimonials)}
              {...settings}
              className="testimonial-slider"
            >
              {this.props.Testimonials.map(testimonial => {
                return (
                  <div key={testimonial.node.id} className="flex flex-col">
                    <p>{testimonial.node.testimonial_description}</p>
                    <h6 className="orange">
                      - {testimonial.node.testimonial_name}
                    </h6>
                  </div>
                )
              })}
            </Slider>
          </div>
        </Container>
      </div>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query TestimonialSlider {
        allStrapiTestimonials {
          edges {
            node {
              id
              testimonial_name
              testimonial_description
            }
          }
        }
      }
    `}
    render={data => (
      <TestimonialSlider Testimonials={data.allStrapiTestimonials.edges} />
    )}
  />
)
